<template>
  <header class="navBarHeader">
    <div class="container-xl" style="background : #ffffff;">
      <b-navbar class="row justify-content-between flex-column flex-md-row">
        <router-link
          to="/main/herbarium/grid"
          class="mr-1 ml-lg-3 mr-lg-4 logo"
        >
          <img src="../assets/mainPage/icons/icon-logo.svg" alt="logo" />
        </router-link>
        <div
          class="order-1 order-md-0 col-12 col-md-7 mt-4 mt-md-0 navBarInput"
        >
          <b-input-group class="navBarHeaderInputWidth w-100">
            <b-input-group-prepend>
              <span class="input-group-text">
                <b-icon icon="search" />
              </span>
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              class="searchInput"
              :placeholder="$t('main_header_menu_search_herbarium')"
              @input="searchInput()"
            />
            <b-input-group-append>
              <span
                class="input-group-text append icon-block"
                @click="showFilter"
              >
                <img
                  src="../assets/icon/icon-arrow.svg"
                  alt="arrow"
                  :class="[{ active: filterShow }, 'icon']"
                />
              </span>
            </b-input-group-append>
          </b-input-group>
          <FilterBar :show="filterShow" @find-filter="searchFilter" />
        </div>
        <b-navbar-nav
          class="d-flex order-0 justify-content-end col-12 col-md-4 pr-0"
        >
          <b-nav-item class="mr-lg-3">
            <router-link to="/partners" style="color: #2c3e50;">
              {{ $t('main_header_menu_route_partners') }}
            </router-link>
          </b-nav-item>
          <div v-if="isLoggedIn">
            <b-nav-item-dropdown right toggle-class="text-dark">
              <template v-slot:button-content>
                <em class="navBarHeaderAdminName font-weight-bold">
                  {{ author }}
                </em>
              </template>
              <b-dropdown-item href="#" to="/profile/info">
                {{ $t('main_header_menu_btn_profile') }}
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="showModal">
                {{ $t('main_header_menu_btn_logout') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </div>

          <div v-else>
            <b-button-group style="margin-right: 20px">
              <b-button
                variant="light"
                size="md"
                to="/login"
                style="background: #ffffff; border: none;"
                class="d-flex align-items-center"
              >
                <img
                  src="../assets/mainPage/icons/icon-login.svg"
                  alt="login"
                  style="margin-right: 0.5rem;"
                />
                {{ $t('main_header_menu_btn_login') }}
              </b-button>
            </b-button-group>
          </div>
          <LanguageSwitcher />
        </b-navbar-nav>
      </b-navbar>
      <Modal
        :confirmation="loginOut"
        :title="$t('component_modal_confirmation_logout_title')"
        :id-modal="'modalLogout'"
      />
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '../components/Modal.vue'
import FilterBar from '@/components/FilterBar/FilterBar.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import router from '../router'
import ClickOutside from 'vue-click-outside'
let timer = null

export default {
  components: {
    Modal,
    FilterBar,
    LanguageSwitcher
  },
  directives: { ClickOutside },
  data() {
    return {
      filterShow: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'author', 'getPerPage', 'getCurrentPage'])
  },
  mounted() {
    if (location.search) {
      this.$store.dispatch(
        'herbarium/fetchDataFilter',
        `/herbarium${location.search}`
      )
    }
  },
  methods: {
    ...mapActions(['loginOut']),
    showModal() {
      this.$root.$emit('bv::show::modal', 'modalLogout', '#btnShow')
    },
    showFilter() {
      this.filterShow = !this.filterShow
    },
    searchFilter(filter) {
      router.push({
        path: this.$route.path + this.constructorQuery(filter)
      })
      this.$store.dispatch(
        'herbarium/fetchDataFilter',
        `/herbarium${this.constructorQuery(filter)}`
      )
      this.filterShow = !this.filterShow
    },
    searchInput() {
      if (timer) {
        clearTimeout(timer)
      }
      if (this.search.length >= 2 && !this.filterShow) {
        timer = setTimeout(() => {
          router.push({
            path: this.$route.path + `?limit=4&offset=1&search=${this.search}`
          })
          this.$store.dispatch(
            'herbarium/fetchDataFilter',
            `/herbarium?limit=4&offset=1&search=${this.search}`
          )
        }, 1000)
      } else if (this.search.length < 1 && !this.filterShow) {
        this.$store.dispatch(
          'herbarium/fetchDataFilter',
          `/herbarium?limit=4&offset=1`
        )
      }
    },
    constructorQuery(options) {
      let resultQuery = '?'
      if (!Object.values(options).every(x => x === null || x === '')) {
        Object.keys(options).reduce((acc, el) => {
          if (Array.isArray(options[el]) && options[el].length > 0) {
            const formatArr = options[el].map(el => el.id).join(',')
            resultQuery += `${el}=${formatArr}&`
          } else {
            if (typeof options[el] === 'string' && options[el]) {
              resultQuery += `${el}=${options[el]}&`
            }
          }
        })
      }
      resultQuery += `limit=${this.getPerPage}&offset=${1}`
      if (this.search) {
        resultQuery += `&search=${this.search}`
      }
      return resultQuery
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  .logo {
    @media (max-width: 426px) {
      position: absolute;
      left: 15px;
    }
  }
  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

.navBarInput {
  position: relative;
}

.icon-block {
  cursor: pointer;
}

.input-group {
  .searchInput {
    border: 1px solid #ced4da !important;
    border-left: none !important;
    border-right: none !important;
    background-color: #f6f6f6 !important;
    &::after {
      position: relative;
      right: 0;
      top: 65%;
      color: #999;
      margin-top: 4px;
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      content: '';
    }
  }
  .input-group-text {
    border-right: none;
    background-color: #f6f6f6 !important;
    outline: none;
  }
  .input-group-text.append {
    border-left: none !important;
    border-right: 1px solid #ced4da !important;
    cursor: pointer;
    .b-icon {
      font-size: 12px;
      color: #969899;
    }
    .icon {
      transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    }
    .icon.active {
      transform: rotate(180deg);
    }
  }
}
.navBarHeaderAdminName {
  font-style: normal;
}
.navbar-nav {
  align-items: center;
}
.navbar-light .navbar-nav .nav-link {
  cursor: pointer;
}
</style>
