<template>
  <div class="d-flex mb-2" :style="{ maxHeight: 38 + 'px' }">
    <div class="pl-0 col-3 text-left align-self-end">
      <span>{{ $t('profile_herbariums_table_family') }}</span>
    </div>
    <div class="col">
      <Multiselect
        v-model="value"
        :options="getFamily"
        label="name"
        track-by="id"
        :multiple="true"
        :placeholder="$t('filter_bar_label_multi')"
        :select-label="$t('vue_multi_select_enter')"
        :deselect-label="$t('vue_multi_select_remove')"
        :selected-label="$t('vue_multi_select_selected_label')"
        @search-change="search"
        @remove="remove"
        @close="close"
      >
        <template slot="noOptions">
          <span>{{ $t('vue_multi_select_no_options') }}</span>
        </template>
        <template slot="noResult">
          <span>{{ $t('vue_multi_select_no_result') }}</span>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  components: { Multiselect },
  data() {
    return {
      value: []
    }
  },
  computed: mapGetters(['getFamily']),
  methods: {
    search(value) {
      if (value.length >= 2) {
        this.$store.dispatch('requesDataFamily', value)
      }
    },
    remove(item) {
      this.$emit(
        'change-selected',
        'family',
        this.value.filter(el => el.id !== item.id)
      )
    },
    close() {
      this.$emit('change-selected', 'family', this.value)
    }
  }
}
</script>

<style></style>
