<template>
  <div :class="[{ 'd-none': !show }, 'filter']">
    <FamilyFilter @change-selected="selectOptions" />
    <GenusFilter :family="selected.family" @change-selected="selectOptions" />
    <SpeciesFilter :genus="selected.genus" @change-selected="selectOptions" />
    <div class="d-flex mb-2">
      <div class="pl-0 col-3 text-left align-self-end">
        <span>
          {{ $t('herbarium_form_page_input_datepicker_label_start') }}
        </span>
      </div>
      <b-input-group class="col-9 align-items-baseline">
        <b-form-input
          id="dateStart"
          v-model="$v.selected.dateStart.$model"
          type="text"
          :state="validateState('dateStart')"
          disabled
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="$v.selected.dateStart.$model"
            :state="validateState('dateStart')"
            button-only
            right
            :locale="$t('current_lang')"
            aria-controls="collectedDateStart"
            button-variant="light"
            :label-help="$t('bottom_label_datepicker')"
            :label-no-date-selected="$t('top_label_datepicker')"
          />
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-form-text
      v-if="$v.selected.dateStart.$dirty && !$v.selected.dateStart.range"
      text-variant="danger"
    >
      {{ $t('herbarium_form_page_input_datepicker_error') }}
    </b-form-text>
    <div class="d-flex mb-2">
      <div class="pl-0 col-3 text-left align-self-end">
        <span>{{ $t('herbarium_form_page_input_datepicker_label_end') }}</span>
      </div>
      <b-input-group class="col-9 align-items-baseline">
        <b-form-input
          id="dateEnd"
          v-model="$v.selected.dateEnd.$model"
          :state="validateState('dateEnd')"
          type="text"
          disabled
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="$v.selected.dateEnd.$model"
            :state="validateState('dateEnd')"
            button-only
            right
            :locale="$t('current_lang')"
            aria-controls="collectedDateStart"
            button-variant="light"
            :label-help="$t('bottom_label_datepicker')"
            :label-no-date-selected="$t('top_label_datepicker')"
          />
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-form-text
      v-if="$v.selected.dateEnd.$dirty && !$v.selected.dateEnd.range"
      text-variant="danger"
    >
      {{ $t('herbarium_form_page_input_datepicker_error') }}
    </b-form-text>

    <button
      type="button"
      class="success ml-auto d-block mt-4"
      @click="findFilter"
    >
      {{ $t('profile_herbariums_handbooks_search') }}
    </button>
  </div>
</template>

<script>
import FamilyFilter from './FamilyFilter'
import GenusFilter from './GenusFilter'
import SpeciesFilter from './SpeciesFilter'
import moment from 'moment'

export default {
  components: {
    FamilyFilter,
    GenusFilter,
    SpeciesFilter
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      selected: {
        family: [],
        genus: [],
        species: [],
        dateStart: '',
        dateEnd: ''
      },
      form: {
        checkbox1: '',
        checkbox2: '',
        colourOfFlower: '',
        collectedDate: ''
      }
    }
  },
  validations: {
    selected: {
      dateStart: {
        range: (value, { dateEnd }) => {
          if (!value) return true
          return (
            value >= moment('1', 'X').format('YYYY-MM-DD') &&
            value <= moment().format('YYYY-MM-DD') &&
            value <= moment(dateEnd).format('YYYY-MM-DD')
          )
        }
      },
      dateEnd: {
        range: (value, { dateStart }) => {
          if (!value) return true
          return (
            value <= moment().format('YYYY-MM-DD') &&
            value >= moment(dateStart).format('YYYY-MM-DD')
          )
        }
      }
    }
  },
  methods: {
    selectOptions(name, arr) {
      this.$set(this.selected, name, arr)
    },
    findFilter() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$emit('find-filter', this.selected)
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.selected[name]
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    }
  }
}
</script>

<style lang="scss">
.filter {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 101;
  padding: 1.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(33, 43, 54, 0.08),
    0px 31px 41px rgba(33, 43, 54, 0.2);
  border-radius: 6px;
  translate: transition(all 0.3s);
  .multiselect__tags {
    background-color: #fff !important;
    border-bottom: 1px solid #f1f1f1 !important;
    border-radius: 0 !important;
    .multiselect__input {
      background: #fff !important;
      border: none !important;
    }
  }
  input.is-invalid {
    border-color: #dc3545 !important;
  }
  input.is-valid {
    border-color: #28a745 !important;
  }
  .is-invalid > .btn-light {
    color: #dc3545;
    border-bottom: 1px solid #dc3545;
  }
  .is-valid > .btn-light {
    color: #28a745;
    border-bottom: 1px solid #28a745;
  }
  .btn-light {
    background: #fff;
    border-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 0;
    color: #dadada;
  }
  .success {
    color: #fff;
  }
  .form-control {
    background-color: #fff !important;
    border: none !important;
    border-bottom: 1px solid #f1f1f1 !important;
    border-radius: 0 !important;
  }
}
</style>
